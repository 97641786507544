import React from 'react'
import { Identity } from '.'

const IdentityList = ({ identities }) => {
  return identities.map((id, index) => (
    <div key={index}>
      <Identity identity={id} />
    </div>
  ))
}

export default IdentityList
