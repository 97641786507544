import React, { useEffect, useState } from 'react'
import { Spinner } from '@blueprintjs/core'
import * as fetchJsonp from 'fetch-jsonp'

const SCRIPT_URL = 'https://production-assets.codepen.io/assets/embed/ei.js'
const OEMBED_URL =
  'https://codepen.io/api/oembed?format=js&callback=callback&url='

const Codepen = ({ url, height }) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const loadScript = () => {
    // load the codepen embed script
    const script = document.createElement('script')
    script.src = SCRIPT_URL
    script.async = true

    /*script.onload = () => {
      setLoaded(true)
    }*/
    script.onerror = () => {
      setError('Failed to load: ', SCRIPT_URL)
    }

    document.body.appendChild(script)
  }

  const loadOEmbedData = async url => {
    try {
      const response = await fetchJsonp(OEMBED_URL + url)
      const json = await response.json()
      const [username, hash] = url
        .match(/https:\/\/codepen\.io\/(.+)\/pen\/(.+)/)
        .slice(1)
      setData({ ...json, username, hash })
    } catch (err) {
      setError(err.message)
    }
  }

  useEffect(() => {
    if (data) {
      loadScript()
    }
  }, [data])

  useEffect(() => {
    loadOEmbedData(url)
  }, [url])

  return error ? (
    <p>{error}</p>
  ) : !data ? (
    <Spinner />
  ) : (
    <p
      data-height={height || '256'}
      data-theme-id="light"
      data-slug-hash={data.hash}
      data-default-tab="js,result"
      data-user={data.username}
      data-embed-version={data.version}
      data-pen-title={data.title}
      data-preview="true"
      className="codepen"
    >
      See the Pen{' '}
      <a href={`https://codepen.io/${data.username}/pen/${data.hash}/`}>
        {data.title}
      </a>{' '}
      by {data.author_name} (
      <a href={`https://codepen.io/${data.username}`}>@{data.username}</a>) on{' '}
      <a href="https://codepen.io">CodePen</a>.
    </p>
  )
}

export default Codepen

/**



 */

/*

<div dangerouslySetInnerHTML={{ __html: data.html }} />

*/
