import React from 'react'
import YouTube from 'react-youtube'
import './Youtube.module.css'

const Youtube = ({ video }) => {
  const matches = video.url.match(
    /^https:\/\/www\.youtube\.com\/watch\?v=(.+)$/
  )
  if (matches && matches[1]) {
    return (
      <div id="widget">
        <YouTube
          videoId={matches[1]}
          opts={{
            width: '100%',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          }}
        />
      </div>
    )
  } else {
    return 'Failed to Load Video'
  }
}

export default Youtube
