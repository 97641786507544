import React from 'react'
import { IconForExperience } from '../Icons'

const Experience = ({ experience }) => {
  return (
    <div className="m-2" style={{ display: 'flex' }}>
      <div style={{ width: '18px', marginRight: '5px' }}>
        <IconForExperience experienceKind={experience.node.kind} />
      </div>
      <div>
        {experience.node.title && experience.node.title + ' at '}
        {experience.node.place.url ? (
          <a
            href={experience.node.place.url}
            title={experience.node.place.name}
          >
            {experience.node.place.name}
          </a>
        ) : (
          experience.node.place.name
        )}
      </div>
    </div>
  )
}

export default Experience

/*

    <div key={experience.node.experienceID} className="m-2">
          <Icon
            icon={index === open ? 'small-minus' : 'small-plus'}
            onClick={() => {
              toggle(index)
            }}
          />
          <IconForExperience experienceKind={exp.node.kind} />{' '}
          {exp.node.title && exp.node.title + ' at '}
          {exp.node.place.url ? (
            <a href={exp.node.place.url} title={exp.node.place.name}>
              {exp.node.place.name}
            </a>
          ) : (
            exp.node.place.name
          )}
          <Collapse isOpen={index === open}>
            <ul>
              <li>
                {DateTime.fromISO(exp.node.start).toFormat('LLL yyyy')} -{' '}
                {exp.node.end
                  ? DateTime.fromISO(exp.node.end).toFormat('LLL yyyy')
                  : 'present'}
              </li>
            </ul>
          </Collapse>
        </div>

        */
