import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Footer, Header } from '../components'

const LandingPageLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Helmet>
            <title>Fizbuz</title>
          </Helmet>
          <Header />
          <div id="landing">
            <Component {...props} />
          </div>
          <Footer />
        </>
      )}
    />
  )
}

export default LandingPageLayout
