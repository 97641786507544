import React, { useCallback, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'
import { useUser } from './UserContext'
import auth from './auth/Auth'
import api from './api/Api'
import { buildUserFromAccount, buildUserFromToken } from './models/User'

async function fetchAccountID(sub) {
  const response = await api.invoke('/graphql', 'POST', {
    query: `{accountIDBySub(sub:"${sub}")}`,
  })
  return response.data.accountIDBySub
}

/** 
  Load the user object into the UserContext if it isn't there already.
  If the user matches a record in the DB, load this additional information.
*/
const LoadUser = ({ location, children, history }) => {
  const [user, setUser] = useUser()

  const loadUserFromAuth = useCallback(async () => {
    try {
      // get the AuthResult object, run a silentAuth if it doesn't exist yet
      const authResult = auth.authResult || (await auth.silentAuth())
      console.log(authResult)
      // use the sub value ("github|xxxx") to look-up the matching accountID
      const accountID = await fetchAccountID(authResult.idTokenPayload.sub)
      console.log(accountID)
      // if the user exists in our system, load their information
      if (accountID) {
        const response = await api.invoke(`/api/accounts/${accountID}`)
        setUser({
          ...buildUserFromToken(user, authResult),
          ...buildUserFromAccount(user, response.data),
        })
        // else if pathname=createAccount, we are creating a NEW user,
        // store a few fields in the global user for use in CreateAccount
      } else if (location.pathname === '/createAccount') {
        setUser(buildUserFromToken({}, authResult))
      } else {
        // someone logged-in using Github, but there is no matching account
        auth.logout(
          'There is no Account that matches this Github login. Try registering?'
        )
        setUser(null)
      }
    } catch (err) {
      // this is an unexpected auth0 error, need to re-direct to error page
      if (err.error !== 'login_required') {
        console.log(err)
        history.push(
          '/error?message=' +
            encodeURI(
              `Error authenticating with Auth0. Error Message: ${err.message}`
            )
        )
      }
      // if app thinks we are authed and error === 'login_required', wipe local auth state
      else if (auth.isAuthenticated() && err.error === 'login_required') {
        auth.logout('Please log-in again')
      }
    }
  }, [history, location, setUser, user])

  useEffect(() => {
    // if there isn't a user in the global store but localStorage indicates a user is logged-in,
    // check to see if we're authenticated and then (if so) set the global user object
    console.log(
      'LoadUser: loggedIn = ',
      localStorage.getItem('loggedIn'),
      typeof localStorage.getItem('loggedIn')
    )
    if (
      !user &&
      (localStorage.getItem('loggedIn') === 'true' ||
        location === '/createAccount')
    ) {
      loadUserFromAuth()
    }
  }, [loadUserFromAuth, location, user])

  // don't try to load the user when visiting the error or callback pages
  //console.log(location.pathname)
  if (location.pathname === '/error' || location.pathname === '/callback') {
    return children
  }

  // if the user is authenticated, but we don't have the user from the global store yet, wait
  if (auth.isAuthenticated() && !user) {
    return <Spinner animation="border" />
  } else {
    //console.log('rendering children of LoadUser')
    return children
  }
}

export default withRouter(LoadUser)
