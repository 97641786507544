import React from 'react'
import { IconForService } from '../Icons'
import { getService } from '../models/Identity'

const Identity = ({ identity }) => {
  return (
    <div>
      <IconForService
        url={getService(identity.node.service).profilePath}
        alt="globe"
      />{' '}
      <a
        title={getService(identity.node.service).name}
        href={
          getService(identity.node.service).profilePath + identity.node.nickname
        }
      >
        {identity.node.nickname}
      </a>
    </div>
  )
}

export default Identity
