import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { UserProvider } from './UserContext'
import { ConfigProvider } from './ConfigContext'
import LoadUser from './LoadUser'
import Routes from './Routes'
import './index.css'

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider>
      <UserProvider>
        <LoadUser>
          <Routes />
        </LoadUser>
      </UserProvider>
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
