import React from 'react'
//import { Card } from '@blueprintjs/core'
import { Activity } from '.'
//import Markdown from 'markdown-to-jsx'

const Activities = ({ account }) => {
  /*const getComponent = activity => {
    if (activity.node.kind === 'Project') {
      return <Project project={activity} />
    } else if (activity.kind === 'Writing') {
      return <Writing writing={activity} />
    } else if (activity.kind === 'Video') {
      return <Video video={activity} />
    }*/

  /*if (project.node.url.match(/github\.com/)) {
      return <Github project={project.node} />
    } else if (project.node.url.match(/codepen\.io/)) {
      return <Codepen url={project.node.url} />
    } else {
      return (
        <Card>
          <h4>
            {project.node.url ? (
              <a href={project.node.url}>{project.node.title}</a>
            ) : (
              project.node.title
            )}
          </h4>
          {project.node.description && (
            <div className="markdown">
              <Markdown>{project.node.description}</Markdown>
            </div>
          )}
        </Card>
      )
    }*/
  //}

  const activityList = account.activitiesConnection.edges.sort(
    (a, b) => new Date(b.node.date) - new Date(a.node.date)
  )

  return (
    <>
      {activityList.map(a => (
        <div key={a.node.activityID} className="module">
          <Activity activity={a} />
        </div>
      ))}
    </>
  )
}

export default Activities
