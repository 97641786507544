import auth0 from 'auth0-js'
import { AUTH_CONFIG } from './auth0-variables'
import Api from '../api/Api'

const auth0Client = new auth0.WebAuth({
  domain: AUTH_CONFIG.domain,
  clientID: AUTH_CONFIG.clientId,
  audience: AUTH_CONFIG.audience,
  redirectUri:
    window.location.protocol + '//' + window.location.host + '/callback',
  responseType: 'token id_token',
  scope: 'openid profile email',
})

class Auth {
  authResult

  login(nextRoute) {
    localStorage.setItem('nextRoute', nextRoute)
    auth0Client.authorize()
  }

  logout(message) {
    localStorage.removeItem('nextRoute')
    localStorage.removeItem('expiresAt')
    localStorage.setItem('loggedIn', 'false')
    auth0Client.logout({
      returnTo:
        window.location.protocol +
        '//' +
        window.location.host +
        (message ? '?message=' + encodeURI(message) : ''),
      clientID: AUTH_CONFIG.clientId,
    })
    this.authResult = null
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      auth0Client.parseHash((err, authResult) => {
        if (err) return reject(err)
        if (!authResult || !authResult.idToken) {
          return reject(err)
        }
        //console.log('handleAuthentication succeeded: ', authResult)
        this.setSession(authResult)
        resolve()
      })
    })
  }

  renewSession(cb) {
    console.log('renewSession')
    auth0Client.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, cb)
      } else if (err) {
        this.logout()
        console.log(err)
        //alert(`Could not get a new token (${err.error}: ${err.error_description}).`)
        cb('Session has expired, please log-in again')
      }
    })
  }

  isAuthenticated() {
    const isTokenExpired =
      new Date().getTime() >= localStorage.getItem('expiresAt')
    if (localStorage.getItem('expiresAt') && isTokenExpired) {
      this.logout()
    }
    return !isTokenExpired && localStorage.getItem('loggedIn') === 'true'
  }

  setSession(authResult) {
    //localStorage.setItem('isLoggedIn', 'true')
    Api.accessToken = authResult.accessToken
    console.log(
      'setSession: Setting expiresAt',
      new Date(authResult.idTokenPayload.exp * 1000)
    )
    localStorage.setItem('expiresAt', authResult.idTokenPayload.exp * 1000)
    localStorage.setItem('loggedIn', 'true')
    this.authResult = authResult
    //if (cb) { cb(null, authResult.idTokenPayload) }
  }

  silentAuth() {
    console.log('silentAuth')
    return new Promise((resolve, reject) => {
      auth0Client.checkSession({}, (err, authResult) => {
        if (err) return reject(err)
        this.setSession(authResult)
        resolve(authResult)
      })
    })
  }
}

const singleton = new Auth()

export default singleton
