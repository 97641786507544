export const SERVICES = [
  { service: 'github', name: 'Github', profilePath: 'https://github.com/' },
  { service: 'twitter', name: 'Twitter', profilePath: 'https://twitter.com/' },
  { service: 'codepen', name: 'Codepen', profilePath: 'https://codepen.io/' },
  {
    service: 'facebook',
    name: 'Facebook',
    profilePath: 'https://facebook.com/',
  },
  {
    service: 'linkedin',
    name: 'LinkedIn',
    profilePath: 'https://linkedin.com/in/',
  },
  {
    service: 'calendly',
    name: 'Calendly',
    profilePath: 'https://calendly.com/',
  },
  {
    service: 'devto',
    name: 'DEV',
    profilePath: 'https://dev.to/',
  },
  {
    service: 'repl.it',
    name: 'Repl.it',
    profilePath: 'https://repl.it/@',
  },
]

export const getService = service =>
  SERVICES.filter(s => s.service === service).pop()
