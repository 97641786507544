import React from 'react'
import Preference from './Preference'

const List = ({ list, filter }) => {
  if (list && filter) {
    list = list.filter(t => (filter === 'positive' ? t.feels > 0 : t.feels < 0))
  }
  return list ? (
    list.map((trait, index) => <Preference key={index} preference={trait} />)
  ) : (
    <div />
  )
}

const Preferences = ({ account }) => {
  //console.log(account.traitsConnection.edges)
  const kindList = account.traitsConnection.edges
    // group the traits by "kind"
    .reduce((acc, v) => {
      ;(acc[v.node.kind] = acc[v.node.kind] || []).push(v)
      return acc
    }, {})

  //console.log(kindList)
  return (
    <div>
      <h2>Job Preferences</h2>
      {Object.keys(kindList).map((kind, index) => (
        <div key={index}>
          {kindList[kind].length > 0 && (
            <div className="m-2">
              <h3>{kind}</h3>
              <List list={kindList[kind]} filter="positive" />
              <List list={kindList[kind]} filter="negative" />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Preferences
