import React from 'react'
import Markdown from 'markdown-to-jsx'
import { Icon } from '../Icons'
import { IdentityList } from '.'
import styles from './Tldr.module.css'

const Tldr = ({ account }) => {
  return (
    <article id={styles.tldr}>
      <div id={styles.left}>
        <div id={styles.avatar}>
          <img src={account.image} alt={'avatar for ' + account.name} />
        </div>
        <div id={styles.info}>
          {account.pronouns && (
            <div>
              <Icon icon="comment-dots" /> {account.pronouns}
            </div>
          )}
          {account.location && (
            <div>
              <Icon icon="map-marker" /> {account.location}
            </div>
          )}
          <IdentityList identities={account.identitiesConnection.edges} />
        </div>
      </div>
      <div id={styles.right}>
        <div id={styles.name}>{account.name}</div>
        {account.about && (
          <div id={styles.about} className="markdown">
            <Markdown>{account.about}</Markdown>
          </div>
        )}
      </div>
    </article>
  )
}

export default Tldr
