export function buildUserFromAccount(user, account) {
  const newUser = {
    ...user,
    accountID: account.accountID,
    email: account.email,
    name: account.name,
    nickname: account.nickname,
    image: account.image,
    published: account.published,
  }
  return newUser
}

export function buildUserFromToken(user, token) {
  const id = token.idTokenPayload
  //console.log(id)
  const newUser = {
    ...user,
    email: id.email,
    name: id.name,
    roles: id['https://fizbuz.com/roles'],
    nickname: id.nickname,
    image: id.picture,
    sub: id.sub,
  }

  return newUser
}

export function isAdminUser(user) {
  return user.roles.indexOf('admin') >= 0
}
