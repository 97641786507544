import React /*, { useState }*/ from 'react'
import { Experience } from '.'

const Experiences = ({ account }) => {
  /*  const [open, setOpen] = useState(null)

  const toggle = index => {
    setOpen(index === open ? null : index)
  }*/

  const sorted = account.experiencesConnection.edges.sort(
    (a, b) => new Date(b.node.start) - new Date(a.node.start)
  )

  return (
    <>
      <h2>Experiences</h2>
      {sorted.map((exp, index) => (
        <Experience key={index} experience={exp} />
      ))}
    </>
  )
}

export default Experiences
