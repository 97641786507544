import React from 'react'
import { Intent, Popover, PopoverInteractionKind, Tag } from '@blueprintjs/core'
import { Icon } from '../Icons'

const buildSkill = tool => {
  return (
    <Tag
      key={tool.node.toolID}
      intent={Intent.PRIMARY}
      interactive={tool.comment}
      minimal={true}
      large={true}
      style={{ margin: '5px' }}
    >
      {tool.node.name} {tool.comment && <Icon icon="info-circle" />}
    </Tag>
  )
}

const Skill = ({ skill }) => {
  return skill.comment ? (
    <Popover
      key={skill.node.toolID}
      interactionKind={PopoverInteractionKind.CLICK}
    >
      {buildSkill(skill)}
      <div className="p-2">{skill.comment && <p>{skill.comment}</p>}</div>
    </Popover>
  ) : (
    buildSkill(skill)
  )
}

export default Skill
