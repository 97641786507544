import React, { useEffect, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import auth from './Auth'
import { Spinner } from '@blueprintjs/core'

const Callback = withRouter(({ location /*, history*/ }) => {
  const [nextRoute, setNextRoute] = useState(null)

  const handleAuthentication = async () => {
    try {
      //console.log('attempting handleAuth')
      await auth.handleAuthentication()
      const next = localStorage.getItem('nextRoute')
      localStorage.removeItem('nextRoute')
      //history.push(next)
      setNextRoute(next)
    } catch (error) {
      //console.log(error)
      setNextRoute('/error?message=' + encodeURIComponent(error.message))
    }
  }

  useEffect(() => {
    // if there's a JWT token in the URL hash, process it for authentication
    if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication()
    }
  }, [location])

  return nextRoute ? <Redirect to={nextRoute} /> : <Spinner />
})

export default Callback
