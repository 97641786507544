import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* 
  NOTE: the code below loads ALL of fontawesome icons. Great for dev, terrible for prod

//import * as icons from '@fortawesome/free-solid-svg-icons'
//import * as pro from '@fortawesome/pro-solid-svg-icons'
// add all solid icons (NOTE: fix this when in production)
let arr = Object.keys(icons)
  .filter(k => k !== 'fas' && k !== 'prefix')
  .map(k => icons[k])

library.add(...arr)

// add all PRO icons (NOTE: fix this when in production)
arr = Object.keys(pro)
  .filter(k => k !== 'fas' && k !== 'prefix')
  .map(k => pro[k])

library.add(...arr)
*/

import('@fortawesome/free-brands-svg-icons').then(({ fab }) => {
  library.add(fab)
})

const traitMapping = {
  'many-hats': 'hat-wizard',
  'pair-programming': 'dice-two',
  'remote-friendly': 'globe',
  'junior-devs': 'book-reader',
  'open-floorplan': 'headphones',
  'product-driven': 'car',
  'tool-choice': 'tools',
  'own-devops': 'server',
  travel: 'plane',
  'small-team': 'users',
  'fast-pace': 'shipping-fast',
  sprints: 'running',
  kanban: 'sticky-note',
  'community-engagement': 'user-friends',
  'values-empathy': 'heart',
  'after-work-fun': 'bowling-ball',
  'few-meetings': 'clock',
  'practices-ci': 'undo',
  'close-office': 'map-marked',
  'free-snacks': 'apple-alt',
  'free-meals': 'pizza',
  startup: 'rocket',
  diverse: 'american-sign-language-interpreting',
  'matching-values': 'thumbs-up',
  'work-life': 'battery-full',
  'solves-problems': 'dove',
  'engineering-driven': 'microchip',
  'design-driven': 'palette',
  'data-driven': 'chart-bar',
  'customer-focused': 'user-check',
  'internal-mobility': 'sitemap',
  'part-time': 'stopwatch',
  'full-time': 'clock',
  'long-term': 'tree',
  'short-term': 'flower-tulip',
  'dog-friendly': 'dog',
  'child-care': 'baby',
  'public-transit': 'bus',
  'vc-funded': 'sack-dollar',
  bootstrapped: 'piggy-bank',
  'pbc-bcorp': 'heart-square',
  safe: 'hand-heart',
  'fast-growing': 'chart-line',
}

const experienceMapping = {
  Employee: 'briefcase',
  Student: 'graduation-cap',
  Attendee: 'chair',
  Speaker: 'podium',
  Instructor: 'chalkboard-teacher',
  Organizer: 'user-hard-hat',
  Volunteer: 'person-carry',
}

// dynmically load our icon libraries (good for code splitting!)
import('@fortawesome/free-solid-svg-icons').then(solidIcons => {
  import('@fortawesome/pro-solid-svg-icons').then(proIcons => {
    // build an array of the names of the icons we need for our app
    const iconNames = Array.from(
      new Set(
        Object.values(traitMapping) // icons we need for Traits
          .concat(
            Object.values(experienceMapping) // icons we need for Experiences
          )
          .concat(
            ['comment-dots', 'map-marker'] // other misc icons we need
          )
      )
    )
    // build an array of fontawesome objects
    const iconClasses = iconNames.map(v => {
      const iconHypens = 'fa-' + v
      const iconCamelCase = iconHypens.replace(/-([a-z])/g, g => {
        return g[1].toUpperCase()
      })
      return solidIcons[iconCamelCase] || proIcons[iconCamelCase]
    })
    // add these icons to the library
    library.add(iconClasses)
  })
})

const getIconForService = function(url, alt) {
  if (url.match(/github\.com/)) {
    return ['fab', 'github']
  } else if (url.match(/medium\.com/)) {
    return ['fab', 'medium']
  } else if (url.match(/youtube\.com/)) {
    return ['fab', 'youtube']
  } else if (url.match(/twitter\.com/)) {
    return ['fab', 'twitter']
  } else if (url.match(/codepen\.io/)) {
    return ['fab', 'codepen']
  } else if (url.match(/linkedin\.com/)) {
    return ['fab', 'linkedin']
  } else if (url.match(/facebook\.com/)) {
    return ['fab', 'facebook']
  } else if (url.match(/calendly\.com/)) {
    return 'calendar-alt'
  } else if (url.match(/dev\.to/)) {
    return ['fab', 'dev']
  } else if (alt) {
    return alt
  }
}

const getIconForTrait = function(trait) {
  return traitMapping[trait]
}

const getIconForExperience = function(experienceKind) {
  return experienceMapping[experienceKind]
}

export const IconForService = ({ url, alt, ...rest }) => {
  return (
    <FontAwesomeIcon
      icon={getIconForService(url, alt)}
      style={{ width: '18px' }}
      {...rest}
    />
  )
}

export const IconForTrait = ({ trait, ...rest }) => {
  return <FontAwesomeIcon icon={getIconForTrait(trait)} {...rest} />
}

export const IconForExperience = ({ experienceKind, ...rest }) => {
  return (
    <FontAwesomeIcon
      icon={getIconForExperience(experienceKind)}
      {...rest}
      style={{ width: '18px' }}
    />
  )
}

export const Icon = ({ icon, ...rest }) => {
  return <FontAwesomeIcon icon={icon} {...rest} style={{ width: '18px' }} />
}
