import React from 'react'
import { IconForService } from '../Icons'
import { Codepen, Github, Youtube } from '.'
import Markdown from 'markdown-to-jsx'

const Activity = ({ activity }) => {
  if (activity.node.url && activity.node.url.match(/github\.com/)) {
    return <Github project={activity.node} />
  } else if (activity.node.url && activity.node.url.match(/codepen\.io/)) {
    return <Codepen url={activity.node.url} />
  } else if (activity.node.url && activity.node.url.match(/youtube\.com/)) {
    return <Youtube video={activity.node} />
  } else {
    return (
      <div>
        <h2>
          {activity.node.url && (
            <IconForService
              url={activity.node.url}
              style={{ width: '18px', marginRight: '5px' }}
            />
          )}
          {activity.node.url ? (
            <a href={activity.node.url}>{activity.node.title}</a>
          ) : (
            activity.node.title
          )}
        </h2>
        {activity.node.image && (
          <div>
            <img src={activity.node.image} alt={activity.node.title} />
          </div>
        )}
        {activity.node.description && (
          <div className="markdown">
            <Markdown>{activity.node.description}</Markdown>
          </div>
        )}
      </div>
    )
  }
}

export default Activity
