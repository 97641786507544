class Api {
  accessToken

  async invoke(url, method = 'GET', postParams = null) {
    const fetchParams = { method }
    if (this.accessToken) {
      fetchParams.headers = {
        Authorization: `Bearer ${this.accessToken}`,
      }
    }
    if (postParams) {
      const data = new URLSearchParams()
      for (const p in postParams) {
        data.append(p, postParams[p])
      }
      fetchParams.body = data
    }
    let data
    try {
      const response = await fetch(url, fetchParams)
      data = await response.json()
    } catch (error) {
      console.log(error)
      data = { status: 'error', message: error.message }
    }
    return data
  }
}

const singleton = new Api()

export default singleton
