import React from 'react'
import Skill from './Skill'

const Skills = ({ account }) => {
  const sorted = account.toolsConnection.edges.sort((a, b) => b.level - a.level)
  return (
    <>
      <h2>Skills</h2>
      {sorted.map((tool, index) => (
        <Skill key={index} skill={tool} />
      ))}
    </>
  )
}

export default Skills
