import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'
import { useApi } from './api/ApiHook'

const ConfigContext = createContext()

function useConfig() {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error(`useConfig must be used within a CountProvider`)
  }
  return context
}

function ConfigProvider(props) {
  const { data } = useApi({ path: '/config' })
  const [config, setConfig] = useState({})
  const value = useMemo(() => [config, setConfig], [config])

  useEffect(() => {
    if (data && data.status !== 'error') {
      setConfig(data)
    }
  }, [data])

  // if this is the /error page, skip to rendering the children
  if (props.location.pathname !== '/error') {
    if (!data) {
      return <Spinner animation="border" />
    } else if (data.status === 'error') {
      return <Redirect to={'/error?message=' + encodeURI(data.message)} />
    } else {
      return <ConfigContext.Provider value={value} {...props} />
    }
  } else {
    return props.children
  }
}

const ConfigProviderWithRouting = withRouter(ConfigProvider)

export { ConfigProviderWithRouting as ConfigProvider, useConfig }
