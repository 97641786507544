import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'
import ReactGA from 'react-ga'
import { AppLayout, DefaultLayout, LandingPageLayout } from './layouts'
import Callback from './auth/Callback'
import auth from './auth/Auth'
import { useUser } from './UserContext'
import { isAdminUser } from './models/User'

const App = lazy(() => import('./pages/App'))
const Home = lazy(() => import('./pages/Home'))
const Login = lazy(() => import('./pages/Login'))
const Signup = lazy(() => import('./pages/Signup'))
const AppError = lazy(() => import('./pages/AppError'))
const Connect = lazy(() => import('./pages/Connect'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const EditAccount = lazy(() => import('./pages/EditAccount'))
const EditChildren = lazy(() => import('./pages/EditChildren'))
const EditPreferences = lazy(() => import('./pages/EditPreferences'))
const EditSkills = lazy(() => import('./pages/EditSkills'))
const NoMatch = lazy(() => import('./pages/NoMatch'))
const Onboard = lazy(() => import('./pages/Onboard'))
const Privacy = lazy(() => import('./pages/Privacy'))
const ViewProfile = lazy(() => import('./pages/ViewProfile'))
const AdminHome = lazy(() => import('./pages/Admin'))
const AdminTools = lazy(() => import('./pages/AdminTools'))
const Tools = lazy(() => import('./pages/Tools'))
const API = lazy(() => import('./pages/API'))

ReactGA.initialize('UA-115446574-2')

const PrivateRoute = ({
  admin = false,
  component: Component,
  layout: Layout = AppLayout,
  ...rest
}) => {
  const [user] = useUser()
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated() && (admin === false || isAdminUser(user)) ? (
          <Layout component={Component} {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const PublicRoute = ({
  component: Component,
  layout: Layout = DefaultLayout,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => <Layout component={Component} {...props} />}
  />
)

const Routes = ({ history }) => {
  // push page view events to GA
  history.listen(location => {
    ReactGA.pageview(location.pathname + location.search)
  })
  return (
    <Suspense fallback={<Spinner animation="border" />}>
      <Switch>
        <PrivateRoute admin exact path="/admin" component={AdminHome} />
        <PrivateRoute admin exact path="/admin/tools" component={AdminTools} />
        <PublicRoute exact path="/home/onboard" component={Onboard} />
        <PublicRoute exact path="/tools" component={Tools} />
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/home/account" component={EditAccount} />
        <PrivateRoute
          exact
          path="/home/preferences"
          component={EditPreferences}
        />
        <PrivateRoute exact path="/home/identities" component={EditChildren} />
        <PrivateRoute exact path="/home/experiences" component={EditChildren} />
        <PrivateRoute exact path="/home/activities" component={EditChildren} />
        <PrivateRoute exact path="/home/skills" component={EditSkills} />
        <PrivateRoute exact path="/home/profile" component={ViewProfile} />
        <PrivateRoute exact path="/home/connect" component={Connect} />
        <PrivateRoute exact path="/home/api" component={API} />
        <PublicRoute
          exact
          path="/"
          component={App}
          layout={LandingPageLayout}
        />
        <PublicRoute exact path="/signup" component={Signup} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute path="/u/:nickname" component={ViewProfile} />
        <PublicRoute exact path="/createAccount" component={CreateAccount} />
        <PublicRoute exact path="/callback" component={Callback} />
        <PublicRoute exact path="/privacy" component={Privacy} />
        <PublicRoute exact path="/error" component={AppError} />
        <PublicRoute path="*" component={NoMatch} />
      </Switch>
    </Suspense>
  )
}

export default withRouter(Routes)
