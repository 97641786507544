import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag } from '@blueprintjs/core'
import Markdown from 'markdown-to-jsx'

const Github = ({ project }) => {
  const [data, setData] = useState({})

  const fetchData = async url => {
    const [user, repo] = url.match(/https:\/\/github\.com\/(.+)\/(.+)/).slice(1)
    const res = await fetch(`https://api.github.com/repos/${user}/${repo}`)
    const json = await res.json()
    setData(json)
  }

  useEffect(() => {
    fetchData(project.url)
  }, [project])

  return (
    <div>
      <h2>
        <FontAwesomeIcon icon={['fab', 'github']} />{' '}
        <a href={data.html_url}>{project.title}</a>
      </h2>
      {project.description && (
        <div className="markdown">
          <Markdown>{project.description}</Markdown>
        </div>
      )}
      <div className="m-y-2">
        {data.language && (
          <Tag minimal={true} style={{ marginRight: '5px' }}>
            {data.language}
          </Tag>
        )}
        {data.stargazers_count && (
          <Tag minimal={true} style={{ marginRight: '5px' }} rightIcon="star">
            {data.stargazers_count}
          </Tag>
        )}
      </div>
    </div>
  )
}

export default Github
