import React from 'react'
import { Alignment, Button, Navbar } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import auth from '../auth/Auth'
import { useUser } from '../UserContext'
import { isAdminUser } from '../models/User'

const Header = () => {
  const [user, setUser] = useUser()
  const isAuthenticated = user && auth.isAuthenticated()

  const logout = () => {
    auth.logout()
    setUser(null)
  }

  return (
    <header>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          {!isAuthenticated && (
            <Navbar.Heading>
              <Link to="/">
                <img
                  id="logo"
                  src="/logo.svg"
                  height="50px"
                  alt="Fizbuz logo"
                  style={{ padding: '1px 0 10px 0' }}
                />
              </Link>
            </Navbar.Heading>
          )}
          {isAuthenticated && (
            <>
              <Link to="/home" className="bp3-button bp3-minimal bp3-icon-home">
                Dashboard
              </Link>
              <Link
                to="/tools"
                className="bp3-button bp3-minimal bp3-icon-wrench"
              >
                Tools
              </Link>
              <Link
                to="/home/connect"
                className="bp3-button bp3-minimal bp3-icon-link"
              >
                Connect
              </Link>
              {/*<Link
                to="/home/api"
                className="bp3-button bp3-minimal bp3-icon-code-block"
              >
                API
              </Link>*/}
              {isAdminUser(user) && <Navbar.Divider />}
              {isAdminUser(user) && (
                <Link
                  to="/admin"
                  className="bp3-button bp3-minimal bp3-icon-lock bp3-intent-warning"
                >
                  Admin
                </Link>
              )}
            </>
          )}
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {isAuthenticated ? (
            <>
              <span id="signed-in-as">Signed in as: {user.name}</span>
              <Button className="m-1" onClick={logout.bind(this)}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Link
                className="bp3-button bp3-minimal bp3-icon-add m-1"
                to="/signup"
              >
                Sign Up
              </Link>

              <Link
                className="bp3-button bp3-minimal bp3-icon-person m-1"
                to="/login"
              >
                Log In
              </Link>
            </>
          )}
        </Navbar.Group>
      </Navbar>
    </header>
  )
}

export default Header
