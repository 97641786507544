import React, { createContext, useContext, useMemo, useState } from 'react'

const UserContext = createContext()

function useUser() {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error(`useUser must be used within a CountProvider`)
  }
  return context
}

function UserProvider(props) {
  const [user, setUser] = useState(null)
  const value = useMemo(() => [user, setUser], [user])
  return <UserContext.Provider value={value} {...props} />
}

export { UserProvider, useUser }
