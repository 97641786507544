import React from 'react'
import { Icon, IconForTrait } from '../Icons'
import { Intent, Popover, PopoverInteractionKind, Tag } from '@blueprintjs/core'

const buildPreference = preference => {
  return (
    <Tag
      key={preference.node.traitID}
      intent={preference.feels > 0 ? Intent.SUCCESS : Intent.DANGER}
      interactive={preference.comment && preference.comment.length > 0}
      minimal={true}
      large={true}
      style={{ margin: '5px' }}
    >
      <IconForTrait trait={preference.node.name} />{' '}
      {preference.node.description}{' '}
      {preference.comment && (
        <Icon
          icon="info-circle"
          intent={preference.feels > 0 ? Intent.SUCCESS : Intent.DANGER}
        />
      )}
    </Tag>
  )
}

const Preference = ({ preference }) => {
  return preference.comment ? (
    <Popover interactionKind={PopoverInteractionKind.CLICK}>
      {buildPreference(preference)}
      <div className="p-2">
        {preference.comment && <p>{preference.comment}</p>}
      </div>
    </Popover>
  ) : (
    buildPreference(preference)
  )
}

export default Preference
