import { useEffect, useState } from 'react'
import Api from './Api'

export function useApi({
  path,
  gqlQuery,
  gqlTransform,
  resource,
  resourceID,
  child,
  query,
}) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  async function fetchPath() {
    const response = await Api.invoke(path)
    setData(response)
  }

  async function fetchGraphql() {
    const response = await Api.invoke('/graphql', 'POST', {
      query: gqlQuery,
    })
    if (gqlTransform) {
      setData(gqlTransform(response.data))
    } else {
      setData(response.data)
    }
  }

  async function fetchList() {
    let queryString = ''
    if (query) {
      const data = new URLSearchParams()
      for (const k in query) {
        data.append(k, query[k])
      }
      queryString = '?' + data
    }
    const response = await Api.invoke(`/api/${resource}${queryString}`)
    setData(response.data)
  }

  async function fetchResource() {
    const response = await Api.invoke(`/api/${resource}/${resourceID}`)
    setData(response.data)
  }

  function createResource(values) {
    return maybeFetch(Api.invoke(`/api/${resource}`, 'POST', values))
  }

  /**
   * Update the given resource ID with the given changes.
   * This ignores the `resourceID` hook argument if provided.
   */
  function updateResource(rID, changes) {
    return maybeFetch(Api.invoke(`/api/${resource}/${rID}`, 'POST', changes))
  }

  /**
   * Delete the given resource ID.
   * This ignores the `resourceID` hook argument if provided.
   */
  function deleteResource(rID) {
    return maybeFetch(Api.invoke(`/api/${resource}/${rID}/del`, 'POST'))
  }

  async function fetchChildren() {
    const response = await Api.invoke(`/api/${resource}/${resourceID}/${child}`)
    setData(response.data)
  }

  function createChild(values) {
    return maybeFetch(
      Api.invoke(`/api/${resource}/${resourceID}/${child}`, 'POST', values)
    )
  }

  function _updateChildOrUpsertJoin(childID, payload) {
    return Api.invoke(
      `/api/${resource}/${resourceID}/${child}/${childID}`,
      'POST',
      payload
    )
  }

  function updateChild(childID, changes) {
    return maybeFetch(_updateChildOrUpsertJoin(childID, changes))
  }

  function upsertJoin(childID, changes, refetch = true) {
    return maybeFetch(_updateChildOrUpsertJoin(childID, changes), refetch)
  }

  function deleteChild(childID) {
    return maybeFetch(
      Api.invoke(
        `/api/${resource}/${resourceID}/${child}/${childID}/del`,
        'POST'
      )
    )
  }

  /** Fetch the appropriate resource based on hook config. */
  async function fetch() {
    setLoading(true)
    if (path) {
      await fetchPath()
    } else if (gqlQuery) {
      await fetchGraphql()
    } else if (resource && resourceID && child) {
      await fetchChildren()
    } else if (resource && resourceID) {
      await fetchResource()
    } else if (resource) {
      await fetchList()
    }
    setLoading(false)
  }

  /** Make a request and then fetch() data if it succeeds. */
  async function maybeFetch(request, enabled = true) {
    const resp = await request
    if (resp.status === 'success' && enabled) fetch()
    return resp
  }

  // depending on how the Hook is initialized, fetch the appropriate resource(s)
  useEffect(() => {
    fetch()
  }, [])

  return {
    data,
    loading,
    setData,
    fetchList,
    fetchResource,
    fetchChildren,
    createResource,
    updateResource,
    deleteResource,
    createChild,
    updateChild,
    upsertJoin,
    deleteChild,
    deleteJoin: deleteChild,
  }
}
